import React from 'react';
import NotificationsPage from '../components/Notifications/NotificationList';


function NotificationsList () {
    return (
        <>
            <NotificationsPage />
        </>
    )
}

export default NotificationsList;