import React from 'react';
import Users from '../components/Users/table';

function UsersList () {
    return (
        <>
            <Users />
        </>
    )
}
export default UsersList;