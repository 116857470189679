import Login from '../components/Auth/Login';

function LoginReg () {
    return (
        <>
            <Login />
        </>
    )
}
export default LoginReg;
